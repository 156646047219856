const useAuthLoginDto = async (payload: LoginPayload) => {
  const response = await useFetchData<FetchData<AuthToken>>("/auth/login", {
    body: payload,
    method: "POST",
  });

  return {
    ...response,
  };
};

export { useAuthLoginDto };
