const AUTH_LOGIN_SCHEMA = schema.object({
  email: schema
    .string({
      required_error: localeRuValidate.required,
    })
    .email({
      message: localeRuValidate.email,
    }),
  password: schema.string({
    required_error: localeRuValidate.required,
  }),
});

type AuthLoginSchemaInfer = SchemaInfer<typeof AUTH_LOGIN_SCHEMA>;

type AuthLoginFields = FormFields<AuthLoginSchemaInfer>;

type AuthLoginForm = LoginPayload;

export { AUTH_LOGIN_SCHEMA };
export type { AuthLoginFields, AuthLoginForm };
