const useAuthLoginForm = () => {
  const { defineField, errors, handleSubmit, setErrors } = useForm<AuthLoginForm>({
    validationSchema: toTypedSchema(AUTH_LOGIN_SCHEMA),
  });

  const fields: AuthLoginFields = {
    email: defineField("email"),
    password: defineField("password"),
  };

  const loading = ref(false);

  const submit = handleSubmit(async (formData) => {
    loading.value = true;

    const { body, success } = await useAuthLoginDto(formData);

    if (success && body.data) {
      const { setAuthToken } = useAuthStore();
      const { setRefreshTokenCookie } = useAuthToken();

      setAuthToken(body.data);
      setRefreshTokenCookie(body.data);

      const { item } = await useGetClientDto();

      if (item.value) {
        const { setClient } = useClientStore();
        const { closeModal } = useModalStore();
        const route = useRoute();
        const queryParameters = route.query;

        setClient(item.value);
        loading.value = false;
        closeModal();

        if (queryParameters[SHARED_URL_QUERY_PARAMETERS.RedirectTo]) {
          await navigateTo(queryParameters[SHARED_URL_QUERY_PARAMETERS.RedirectTo] as string, {
            external: true,
          });

          return;
        }

        await navigateTo("/client/projects");
      }
    }

    if (!success) {
      setErrorsFields.rest(setErrors, body);
    }

    loading.value = false;
  });

  return {
    errors,
    fields,
    handleSubmit: submit,
    loading,
  };
};

export { useAuthLoginForm };
